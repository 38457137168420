'use client';

import { DataToast, Loading } from 'components';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { notifyException } from 'services/appsignal';

interface IErrorPage {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error }: IErrorPage) {
  const t = useTranslations();
  const router = useRouter();

  const handleError = useCallback(async () => {
    notifyException(error);
    toast.error(DataToast, {
      data: { content: t('messages.error.something_happen') }
    });
    router.push('/');
  }, [error, router, t]);

  useEffect(() => {
    handleError();
  }, [handleError]);

  return <Loading />;
}
